// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import SearchResultsPage from "./components/SearchResultsPage";
import BookingForm from "./components/BookingForm";
import Login from "./components/Login";
import AdminPage from "./components/AdminPage";
import ThankYouPage from "./components/ThankYouPage";
import {
  AntiAuth,
  AuthProvider,
  RequireAuth,
  RequireAdminAuth,
} from "./components/Auth/Provider";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <HomePage />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <AntiAuth>
                  <Login />
                </AntiAuth>
              }
            />
            <Route
              path="/admin"
              element={
                <RequireAdminAuth>
                  <AdminPage />
                </RequireAdminAuth>
              }
            />
            <Route
              path="/search"
              element={
                <RequireAuth>
                  <SearchResultsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/book/:slotId"
              element={
                <RequireAuth>
                  <BookingForm />
                </RequireAuth>
              }
            />
            <Route path="/thank-you" element={<ThankYouPage />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
