import React from "react";
import { Link } from "react-router-dom";

const close = () => {
  window.close();
};

function HomePage() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="mb-4 text-4xl font-semibold text-gray-800">
          Welcome to the circle.cloud scheduler
        </h1>
        <p className="mb-8 text-lg text-gray-600">
          In order to begin using this application, close this window, visit a
          lead on the CRM and hit 'Schedule'.
        </p>
      </div>
    </div>
  );
}

export default HomePage;

/*

removed section:
<a
  href="https://crm.zoho.com/crm/org57963813/tab/Leads"
  target="_self"
  className="inline-block rounded bg-blue-500 px-6 py-3 text-lg text-white shadow transition-colors hover:bg-blue-600"
>
  Go to leads
</a>
<button onClick={close}>Close tab</button>
<Link to="/search">Search</Link>
<Link to="/admin" className="mt-4 inline-block">
  Admin Panel
</Link>

*/
