// Provider.js

import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UnauthorizedModal } from "../UnauthorizedModal.js";

export const AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/check-auth`,
        {
          method: "GET",
          credentials: "include",
        },
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Check-auth response:", data);
        if (data.authenticated) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } else {
        setUser(null);
        console.error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const login = (provider) => {
    const redirectUrl = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
    console.log('Redirecting to auth URL:', redirectUrl);
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (!loading && user) {
      const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [name, value] = cookie.trim().split('=');
        acc[name] = decodeURIComponent(value);
        return acc;
      }, {});
      const redirectUrl = cookies.loginRedirectUrl;
      console.log('User logged in. Redirect URL from cookie:', redirectUrl);
      if (redirectUrl) {
        // Remove the cookie
        document.cookie = 'loginRedirectUrl=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        console.log('Redirecting to:', redirectUrl);
        window.location.href = redirectUrl;
      } else {
        console.log('No redirect URL found in cookie');
      }
    }
  }, [user, loading]);

  const contextValue = {
    user,
    loading,
    login,
    setUnauthorized: () => setIsUnauthorized(true),
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      <UnauthorizedModal
        isOpen={isUnauthorized}
        onClose={() => setIsUnauthorized(false)}
      />
    </AuthContext.Provider>
  );
}

export function RequireAuth({ children }) {
  const { user, loading, login } = React.useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      const currentPath = location.pathname + location.search;
      document.cookie = `loginRedirectUrl=${encodeURIComponent(currentPath)}; path=/; max-age=3600; SameSite=Strict`;
    }
  }, [user, loading, location]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function RequireAdminAuth({ children }) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();
  const allowedEmails = ["axel@circle.cloud", "anotheradmin@example.com"]; // Update with actual emails

  if (!auth.user || !allowedEmails.includes(auth.user?.email)) {
    // Redirect if not logged in or if the user's email isn't in the allowed list
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function AntiAuth({ children }) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

// Add this custom hook
export function useAuth() {
  return useContext(AuthContext);
}
