import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./tailwind.output.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Add the error handling code here
window.onerror = function (message, source, lineno, colno, error) {
  fetch("/api/log-error", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      message: message,
      stack: error ? error.stack : "",
      url: window.location.href,
    }),
  });
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);

reportWebVitals();
