import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import ManualBookingModal from "./ManualBookingModal";
import { Loader } from "./Loader-light.js";
import { AuthContext } from "./Auth/Provider.js"; // Adjust the import path as necessary

const dayToNumber = (day) => {
    switch (day) {
        case "Mon":
            return 1;
        case "Tue":
            return 2;
        case "Wed":
            return 3;
        case "Thu":
            return 4;
        case "Fri":
            return 5;
        case "Sat":
            return 6;
        case "Sun":
            return 7;
        default:
            return null;
    }
};

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const slotToDescription = (slot) => {
    const currentDay = moment().isoWeekday(); // Current day (1 = Monday, 7 = Sunday)

    const weekNum = parseInt(slot.substring(1, 2), 10);
    const day = slot.substring(2, 5);
    const time = parseInt(slot.substring(5, slot.length - 2), 10);
    const timePeriod = slot.slice(-2);

    const daysToAdd = weekNum * 7 + dayToNumber(day) - currentDay;
    const desiredDate = moment().add(daysToAdd, "days");

    let weekDescription;
    if (weekNum === 0) {
        weekDescription = "(this week)";
    } else if (weekNum === 1) {
        weekDescription = "(next week)";
    } else {
        weekDescription = `(week after next)`;
    }

    // Adjust for AM and PM
    let formattedTime;
    if (timePeriod === "am") {
        formattedTime = `${time} am`;
    } else {
        formattedTime = time === 12 ? `${time} pm` : `${time % 12} pm`;
    }

    return `${formattedTime}, ${desiredDate.format(
        "dddd D MMMM"
    )} ${weekDescription}`;
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Sorting function
const dayOrder = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
};

const sortSlots = (data) => {
    const sortedSlots = [];
    data.forEach((salesperson) => {
        salesperson.slots.forEach((slot) => {
            sortedSlots.push({
                slot,
                salesPersonName: salesperson.salesPersonName,
                salesPersonID: salesperson.salesPersonID,
            });
        });
    });

    sortedSlots.sort((a, b) => {
        const [aWeek, aDay, aTime] = a.slot
            .match(/W(\d+)(\w{3})(\d+\w{2})/)
            .slice(1);
        const [bWeek, bDay, bTime] = b.slot
            .match(/W(\d+)(\w{3})(\d+\w{2})/)
            .slice(1);

        if (aWeek !== bWeek) {
            return aWeek.localeCompare(bWeek);
        }

        if (dayOrder[aDay] !== dayOrder[bDay]) {
            return dayOrder[aDay] - dayOrder[bDay];
        }

        return aTime.localeCompare(bTime);
    });

    return sortedSlots;
};

function SearchResultsPage() {
    //  console.log("SearchResultsPage");
    const [isLoading, setIsLoading] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);
    const query = useQuery();
    const postcode = query.get("postcode");
    const [allSlots, setAllSlots] = useState([]);
    const [displayedSlots, setDisplayedSlots] = useState([]);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bookingDetails, setBookingDetails] = useState({});
    const [isManualBookingModalOpen, setIsManualBookingModalOpen] =
        useState(false);

    const { setUnauthorized } = useContext(AuthContext); // Destructure setUnauthorized from context

    const navigate = useNavigate();

    const handleAdminButtonClick = (e) => {
        e.preventDefault();
        // Here you define your logic for when to show the modal.
        // For demonstration, let's assume you want to show it when user is unauthorized.
        // Normally, you'd check some condition here, e.g., if the user lacks admin rights.
        setUnauthorized(); // This will show the unauthorized modal
    };

    const handleModalSubmit = (Email) => {
        setIsModalOpen(false);
        setIsLoading(true);

        const requestData = {
            ...bookingDetails,
            emailRecepient: Email,
        };

        fetch(`${process.env.REACT_APP_API_URL}/api/calendarInvite`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(() => {
                //  .then((responseData) => {
                //   console.log("Successfully sent calendar invite:", responseData);
                navigate(
                    `/book/${bookingDetails.slotID}?deviation=0&lead=${bookingDetails.leadID}&spid=${bookingDetails.salesPersonID}&inviteEmail=${Email}`
                );
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    };

    // For manual bookings
    const handleManualBookingModalToggle = () => {
        setIsManualBookingModalOpen(!isManualBookingModalOpen);
    };

    const handleManualBookingConfirm = (selectedDate, selectedTime) => {
        // Assuming selectedDate and selectedTime are in the format you need
        // Or format them as needed

        const leadID = query.get("lead"); // Get the lead ID from the query parameters or another source

        const requestData = {
            leadID,
            manualDate: selectedDate,
            manualTime: selectedTime,
        };

        setIsLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/manualBookingController`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(() => {
                //      .then((data) => {
                //    console.log("Manual booking successful:", data);
                // Handle successful booking (e.g., navigate to another page or show success message)
            })
            .catch((error) => {
                console.error("Error during manual booking:", error);
                // Handle error
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Here we are calling the back end API to obtain lead details to get the email and display on the modal window
    function fetchLeadEmail(leadId) {
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/leadDetails/${leadId}`,
            {
                credentials: "include",
            }
        )
            .then((response) => response.json())
            .then((data) => data.Email);
    }

    //-----------THIS IS THE ACTION FOR THE BOOK BUTTON TO SEND THE EMAIL WITH THE CALENDAR INVITE IN IT --------

    function handleBookingClick(slotID, leadID, salesPersonID, event) {
        event.preventDefault();
        setIsLoading(true);
        fetchLeadEmail(leadID).then((Email) => {
            setBookingDetails({ slotID, leadID, salesPersonID, Email });
            setIsModalOpen(true);
            setIsLoading(false);
        });
    }

    //--------------------------------------------------------------------------------------------------------

    const handleShowMore = () => {
        //   console.log("Handle Show More triggered");

        let uniqueSlots = [];

        // Filter out duplicate slots while maintaining salesperson information
        let addedSlots = new Set(displayedSlots.map((slot) => slot.slot)); // Create a Set of already added slots
        for (let i = 0; i < allSlots.length; i++) {
            const currentSlot = allSlots[i].slot;
            if (!addedSlots.has(currentSlot)) {
                uniqueSlots.push(allSlots[i]);
                addedSlots.add(currentSlot); // Add to the Set of already added slots
                if (uniqueSlots.length === 3) {
                    break;
                }
            }
        }

        setDisplayedSlots((prevDisplayedSlots) => [
            ...prevDisplayedSlots,
            ...uniqueSlots,
        ]);
        //   console.log(displayedSlots); // <-- Add this line here to inspect the updated displayedSlots
        setShowMoreButton(uniqueSlots.length === 3);
    };

    const hasFetchedRef = useRef(false);

    useEffect(() => {
        // Declare timeoutId at the top level of useEffect
        let timeoutId;

        if (!hasFetchedRef.current) {
            // console.log(
            //   "Making API call for postcode from search results page:",
            //   postcode,
            // );

            setIsLoading(true);

            // Assign timeoutId here
            timeoutId = setTimeout(() => {
                setIsLoading(false);
                setHasFetched(true); // Assume no data if timeout
            }, 12000);

            fetch(
                `${process.env.REACT_APP_API_URL}/api/search?postcode=${postcode}`,
                {
                    credentials: "include",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    clearTimeout(timeoutId); // Clear the timeout if data is fetched
                    const sortedSlots = sortSlots(data);
                    setAllSlots(sortedSlots);

                    // This part will make sure initial slots are unique
                    let uniqueInitialSlots = [];
                    let addedSlots = new Set();
                    for (let i = 0; i < sortedSlots.length; i++) {
                        if (!addedSlots.has(sortedSlots[i].slot)) {
                            uniqueInitialSlots.push(sortedSlots[i]);
                            addedSlots.add(sortedSlots[i].slot);
                        }
                        if (uniqueInitialSlots.length === 3) {
                            break;
                        }
                    }

                    setDisplayedSlots(uniqueInitialSlots);
                    setShowMoreButton(uniqueInitialSlots.length === 3);
                    setIsLoading(false); // Stop loading
                    setHasFetched(true); // Data fetched, update state
                })
                .catch((error) => {
                    console.error(error);
                    clearTimeout(timeoutId); // Clear the timeout in case of error
                    setIsLoading(false); // Stop loading on error
                    setHasFetched(true); // Fetch failed, update state
                })
                .finally(() => {
                    hasFetchedRef.current = true; // Prevents additional fetches
                });
        }
        // Clean up function that clears the timeout when the component unmounts or before re-running the effect
        return () => clearTimeout(timeoutId);
    }, [postcode]);

    const EmailModal = ({ isOpen, onClose, onSubmit, initialEmail = "" }) => {
        const [Email, setEmail] = useState(initialEmail);
        const modalContentRef = useRef(null);

        const handleSubmit = (e) => {
            e.preventDefault();
            onSubmit(Email);
        };

        // Add event listener when the modal is open
        useEffect(() => {
            // Function to handle outside click
            const handleOutsideClick = (event) => {
                if (
                    modalContentRef.current &&
                    !modalContentRef.current.contains(event.target)
                ) {
                    onClose();
                }
            };

            if (isOpen) {
                document.addEventListener("mousedown", handleOutsideClick);
            }
            return () => {
                document.removeEventListener("mousedown", handleOutsideClick);
            };
        }, [isOpen, onClose]);

        if (!isOpen) return null;

        return (
            <div className="fixed inset-0 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50">
                <div
                    className="relative top-20 mx-auto w-96 rounded-md border bg-white p-5 shadow-lg"
                    ref={modalContentRef}
                >
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label
                                htmlFor="email"
                                className="mb-2 block text-sm font-bold text-gray-700"
                            >
                                Email for calendar invitation
                            </label>
                            <input
                                type="Email"
                                id="Email"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                            />
                        </div>
                        <div className="flex items-center justify-end">
                            <button
                                type="submit"
                                className="rounded border border-gray-500 px-4 py-2 font-semibold text-gray-600 hover:border-transparent hover:bg-orange-500 hover:text-white"
                            >
                                Invite
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    return (
        <div className="flex min-h-screen items-center justify-center bg-gray-100">
            {isLoading ? (
                <Loader />
            ) : hasFetched ? (
                <div>
                    {displayedSlots.length > 0 ? (
                        <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
                            <div className="w-full max-w-xl rounded-lg bg-white p-6 shadow-lg">
                                <div className="mb-4 sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                                            Available Slots
                                        </h1>
                                        <p className="mt-2 text-sm text-gray-700">
                                            Choose a slot that suits the
                                            customer:
                                        </p>
                                    </div>
                                    {showMoreButton && (
                                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                            <button
                                                onClick={handleShowMore}
                                                className="block rounded-lg bg-orange-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-200"
                                            >
                                                Show More
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="-mx-4 mt-4 overflow-hidden rounded-lg border border-gray-200 sm:mx-0">
                                    <table className="h-full w-full min-w-full divide-y divide-gray-200 bg-white">
                                        <tbody>
                                            {displayedSlots.map(
                                                (result, index) => (
                                                    <tr
                                                        key={index}
                                                        className={classNames(
                                                            index === 0
                                                                ? ""
                                                                : "border-t border-transparent",
                                                            "hover:bg-gray-50"
                                                        )}
                                                    >
                                                        <td className="px-4 py-3.5 pl-6 pr-3 text-sm">
                                                            <div className="font-medium text-gray-900">
                                                                {slotToDescription(
                                                                    result.slot
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3.5 pr-6 text-right text-sm font-medium">
                                                            <button
                                                                onClick={(e) =>
                                                                    handleBookingClick(
                                                                        result.slot,
                                                                        query.get(
                                                                            "lead"
                                                                        ),
                                                                        result.salesPersonID,
                                                                        e
                                                                    )
                                                                }
                                                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            >
                                                                Book
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-4 text-gray-700">
                            No available slots for this location over the next
                            two weeks.
                        </div>
                    )}
                </div>
            ) : null}{" "}
            {/* Don't render anything until loading is complete or has fetched */}
            <button
                onClick={handleAdminButtonClick} // Use the handler here
                className="text-md fixed bottom-4 left-4 rounded-lg bg-gray-500 px-4 py-2 font-normal text-white shadow-sm hover:bg-gray-600"
            >
                Admin Panel
            </button>
            <button
                onClick={handleManualBookingModalToggle}
                className="text-md fixed bottom-4 right-4 rounded-lg bg-blue-950 px-4 py-2 font-normal text-white shadow-sm hover:bg-blue-900"
            >
                Manual Booking
            </button>
            <ManualBookingModal
                isOpen={isManualBookingModalOpen}
                onClose={handleManualBookingModalToggle}
                onConfirm={handleManualBookingConfirm}
                handleBookingClick={handleBookingClick} // assuming this function is defined in the parent
            />
            <EmailModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
                initialEmail={bookingDetails.Email || ""}
            />
        </div>
    );
}

export default SearchResultsPage;
