import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles/calendarStyles.css";
import { Loader } from "./Loader-dark.js";

function ManualBookingModal({ isOpen, onClose, onConfirm }) {
    const modalRef = useRef(null);
    const [hour, setHour] = useState("8"); // New state for hour
    const [minute, setMinute] = useState("00"); // New state for minute
    const [ampm, setAmPm] = useState("am"); // New state for AM/PM
    const navigate = useNavigate(); // Initialize navigate
    const [isLoading, setIsLoading] = useState(false);
    const [slotDeviationMinutes, setSlotDeviationMinutes] = useState(0);
    const [calculatedSlot, setCalculatedSlot] = useState("W0Mon10am");
    const leadID = new URLSearchParams(window.location.search).get("lead");
    const [leadEmail, setLeadEmail] = useState("");

    // Combining states: control both the email field and button text with one state
    const [bookingStage, setBookingStage] = useState("confirm"); // 'confirm', 'invite'

    // New state for email
    const [email, setEmail] = useState("");

    const [date, setDate] = useState(new Date());

    // Use the current date as the minimum date for the calendar
    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 to include today

    const handleBookClick = () => {
        if (bookingStage === "confirm") {
            // Existing logic for confirming the date and time selection
            const timeOffset = ampm === "pm" && parseInt(hour) !== 12 ? 12 : 0;
            const timeInHours24Format = (parseInt(hour) % 12) + timeOffset;
            const bookingDateTime = new Date(
                date.setHours(timeInHours24Format, parseInt(minute))
            );

            //    console.log("Booking Dssssate:", bookingDateTime);

            setIsLoading(true);
            fetch(
                `${
                    process.env.REACT_APP_API_URL
                }/api/dateToSlot/${bookingDateTime.toISOString()}`,
                {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                    credentials: "include",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setIsLoading(false);
                    setSlotDeviationMinutes(data.deviationMinutes);
                    setCalculatedSlot(data.slot);
                    //   console.log("Slot deviation mins:", data.deviationMinutes);
                    //    console.log("slot found data", data.slot);
                    setBookingStage("invite"); // Proceed to the next stage to get the email
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setIsLoading(false);
                });
        } else if (bookingStage === "invite") {
            // Call handleSendInvite when the booking stage is "invite"
            //   console.log("About to run the fetch email request for lead: ", leadID);
            handleSendInvite(date);
        }
    };

    // Function to handle sending the invite
    const handleSendInvite = () => {
        setIsLoading(true);

        // Create a new Date object from the selected date
        const dateTime = new Date(date);

        // console.log("Hour:", hour);
        // console.log("Minute:", minute);
        // console.log("AM/PM:", ampm);

        // Convert 12-hour format to 24-hour format
        let hour24 = parseInt(hour);
        if (ampm === "pm" && hour24 < 12) {
            hour24 += 12;
        } else if (ampm === "am" && hour24 === 12) {
            hour24 = 0;
        }

        // Set the hours and minutes for the dateTime
        dateTime.setHours(hour24, parseInt(minute));

        //  console.log("Combined date and time to send:", dateTime);

        const requestData = {
            emailRecepient: email,
            leadID: leadID,
            selectedDateTime: dateTime.toISOString(), // Use the date state here
            slotID: calculatedSlot,
            deviation: slotDeviationMinutes,
            salesPersonID: "1624422000000085003",
        };

        //    console.log("The recepient of the email invite will be", email);

        fetch(`${process.env.REACT_APP_API_URL}/api/calendarInvite`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(() => {
                // .then((responseData) => {
                //  console.log("Successfully sent calendar invite:", responseData);
                navigate(
                    `/book/${requestData.slotID}?deviation=${requestData.deviation}&lead=${requestData.leadID}&spid=${requestData.salesPersonID}&inviteEmail=${requestData.emailRecepient}`
                );
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });

        // Reset states and close modal
        setEmail("");
        setBookingStage("confirm");
        setIsLoading(true);
    };

    // ---------------------------------------------------------------//
    // END OF FUNCTION TO SEND INVITE
    // ---------------------------------------------------------------//

    // Update the useEffect to fetch the email when the component mounts or leadID changes
    useEffect(() => {
        const fetchEmailForLead = async (leadID) => {
            return fetch(
                `${process.env.REACT_APP_API_URL}/api/leadDetails/${leadID}`,
                {
                    credentials: "include",
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch email");
                    }
                    return response.json();
                })
                .then((data) => {
                    const email = data.Email;
                    setLeadEmail(email); // Keep this if you need to use leadEmail elsewhere
                    setEmail(email); // This will set the default value in the input box
                    return email;
                })

                .catch((error) =>
                    console.error("Error fetching email:", error)
                );
        };
        if (leadID && !email) {
            fetchEmailForLead(leadID).then((fetchedEmail) => {
                setLeadEmail(fetchedEmail);
                if (!email) {
                    setEmail(fetchedEmail); // Initialize email with fetched email only if it's not set
                }
            });
        }
    }, [leadID, email]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) return null;

    const modalBackdropStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 50,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
    };

    // Function to handle time change
    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        if (name === "hours") {
            setHour(value);
            // Automate AM/PM based on the hour
            if (["8", "9", "10", "11"].includes(value)) {
                setAmPm("am");
            } else {
                setAmPm("pm");
            }
        } else if (name === "minutes") {
            setMinute(value);
        }
        // AM/PM selection is now automated
    };

    // blocking out weekends
    const isWeekend = (date) => {
        const day = date.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
        return day === 0 || day === 6; // Return true for Saturday (6) or Sunday (0)
    };

    // blocking out any dates beyond two weeks from the next Monday
    const today = new Date();
    const nextWeekStart = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (8 - today.getDay())
    );
    const twoWeeksFromNextWeekStart = new Date(
        nextWeekStart.getTime() + 13 * 24 * 60 * 60 * 1000
    ); // Adding 13 days as we don't want to include the monday of the week after next

    return (
        <div style={modalBackdropStyle}>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="modal-content">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <div
                            ref={modalRef}
                            className="relative rounded-lg bg-white p-6 shadow-xl"
                        >
                            {/* Replaced Datepicker with standard HTML date input */}
                            <div className="flex justify-center">
                                <Calendar
                                    onChange={setDate}
                                    value={date}
                                    minDate={minDate}
                                    maxDate={twoWeeksFromNextWeekStart}
                                    tileDisabled={({ date, view }) =>
                                        view === "month" && isWeekend(date)
                                    }
                                />
                            </div>

                            <div className="mt-4 flex w-full flex-col items-center rounded-lg bg-white p-4 shadow-md">
                                <div className="mb-4 flex w-full items-center justify-between">
                                    {/* Time Picker Start */}
                                    <div className="flex items-center">
                                        <span className="text-md font-normal text-slate-600">
                                            Time:
                                        </span>
                                        <div className="ml-4 flex items-center">
                                            {/* Time Picker Select Elements */}
                                            <select
                                                name="hours"
                                                value={hour}
                                                onChange={handleTimeChange}
                                                className="appearance-none bg-transparent text-lg outline-none"
                                            >
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                            <span className="mx-2 text-lg">
                                                :
                                            </span>
                                            <select
                                                name="minutes"
                                                className="appearance-none bg-transparent text-lg outline-none"
                                            >
                                                <option value="0">00</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                            </select>
                                            <select
                                                name="ampm"
                                                className="ml-5 appearance-none bg-transparent text-lg outline-none"
                                                value={ampm}
                                                onChange={handleTimeChange}
                                                disabled // Optional: disable manual changes
                                            >
                                                <option value="am">AM</option>
                                                <option value="pm">PM</option>
                                            </select>
                                        </div>
                                    </div>

                                    <button
                                        onClick={handleBookClick}
                                        style={{ maxWidth: "110px" }}
                                        className="rounded-lg bg-green-600 px-4 py-1.5 text-lg font-semibold text-white shadow-sm hover:bg-green-500"
                                    >
                                        {bookingStage === "confirm"
                                            ? "Confirm"
                                            : "Invite"}
                                    </button>
                                </div>

                                {/* Email Input Section*/}
                                {bookingStage === "invite" && (
                                    <div className="w-full">
                                        <input
                                            type="email"
                                            value={email} // Use 'email' state here
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                            className="w-full rounded border border-gray-300 p-2"
                                        />
                                    </div>
                                )}
                                {/* End of email Input Section*/}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ManualBookingModal;
