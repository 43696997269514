import React from 'react';
import { useAuth } from './Auth/Provider';

const ZohoLoginButton = () => {
  const { login } = useAuth();

  const handleZohoLogin = () => {
    login('zoho');
  };

  return (
    <button
      onClick={handleZohoLogin}
      className="w-52 flex items-center justify-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="#03A9F4" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 3h-15A1.5 1.5 0 003 4.5v15A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0019.5 3zM17 17H7l6.5-9H7V6h10l-6.5 9H17v2z"/>
      </svg>
      Sign in with Zoho
    </button>
  );
};

export default ZohoLoginButton;